<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ appTitle }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> Hoşgeldiniz 👋 </b-card-title>
          <b-card-text class="mb-2"> Lütfen Kullanici bilgilerinizi giriniz. </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input
                    class="rounded-0"
                    id="login-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <label for="login-password">Şifre</label>
                <validation-provider #default="{ errors }" name="Sifre" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="form.sifre"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge rounded-0"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <div class="d-flex justify-content-between">
                  <b-link :to="{ name: 'forgot-password' }">
                    <small>Şifremi Unuttum</small>
                  </b-link>
                </div>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="form.rememberme" name="checkbox-1"> Beni Hatırla </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button squared type="submit" variant="primary" block @click="validationForm" :disabled="show">
                <b-spinner small v-show="show" />
                Giriş Yap
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Platforma da yeni iseniz ? </span>
            <b-link :to="{ name: 'register' }">
              <span>&nbsp;Lütfen üye olunuz.</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">ve ya</div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import CryptoJS from 'crypto-js';
export default {
  components: {
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    show: false,
    form: {
      email: '',
      sifre: '',
      rememberme: false,
    },
    sideImg: require('@/assets/images/pages/login-v2.svg'),
    // validation rulesimport store from '@/store/index'
    required,
    email,
  }),
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    if (this.$cookies.get('rememberme')) {
      let bytes = CryptoJS.AES.decrypt(this.$cookies.get('sifre'), 'sideturkey');
      let pass = bytes.toString(CryptoJS.enc.Utf8);
      this.form.email = this.$cookies.get('email');
      this.form.sifre = pass;
      this.form.rememberme = true;
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.show = true;
          this.$store
            .dispatch('login', this.form)
            .then((res) => {
              if (res.data.success == true) {
                if (this.form.rememberme === true) {
                  let password = CryptoJS.AES.encrypt(this.form.sifre, 'sideturkey').toString();
                  this.$cookies.set('rememberme', this.form.rememberme);
                  this.$cookies.set('email', this.form.email);
                  this.$cookies.set('sifre', password);
                } else {
                  this.$cookies.remove('rememberme');
                  this.$cookies.remove('email');
                  this.$cookies.remove('sifre');
                }
                this.$router.replace(getHomeRouteForLoggedInUser('admin')).then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Hoşgeldeniz sayın ${res.data.data.user.name}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Başarılı şekilde giriş yaptınız.`,
                    },
                  });
                });
                this.show = false;
              }
            })
            .catch((err) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Hata`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `Kullanıcı adı ve şifrenizi kontrol ediniz.`,
                },
              });
              this.show = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
